import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout, Hero, CategorySummary } from '../components'
import { featuredImgProps, heroProps } from '../proptypes'
import { removeTrailingSlash } from '../utils'

const AskArchiveTemplate = ({ hero, featuredImage, slug, categories }) => (
  <div
    id="content"
    className={`content-archive content-archive-${slug.replace('/', '')}`}
  >
    <Hero {...hero} featuredImage={featuredImage} />
    {!!categories && categories.length && (
      <div id="inner-content" className="wrap">
        <section className="all-category-summary">
          {categories.map(
            ({
              frontmatter: {
                hero: { header },
                featuredImage: { alt },
              },
              fields: { slug, imageDesktop },
              articles,
            }) => (
              <CategorySummary
                key={uuidv4()}
                slug={removeTrailingSlash(slug)}
                title={header}
                alt={alt}
                src={imageDesktop}
                articles={articles}
              />
            ),
          )}
        </section>
      </div>
    )}
  </div>
)

AskArchiveTemplate.propTypes = {
  hero: heroProps,
  featuredImage: featuredImgProps,
  slug: PropTypes.string.isRequired,
  categories: PropTypes.array,
}

function sortPostsByCategory(posts, categories) {
  const output = {}
  categories.forEach((cat) => {
    const key = cat.fields.slug
      .split('/')
      .filter((i) => i)
      .pop()
    output[key] = []
  })
  posts.forEach(({ fields: { slug }, frontmatter: { categories, title } }) => {
    const [, defaultCat] = slug.split('/').filter((i) => i)
    const cats = categories.map((item) =>
      item
        .split(' ')
        .map((name) => name.toLowerCase())
        .join('-'),
    )
    if (!cats.includes(defaultCat)) {
      cats.push(defaultCat)
    }
    cats.forEach((cat) => {
      output[cat].push({ slug, title })
    })
  })
  return output
}

const AskArchive = ({ data }) => {
  const {
    frontmatter: { hero, featuredImage },
    fields: { slug, imageMobile, imageTablet, imageDesktop },
  } = data.markdownRemark
  if (featuredImage) {
    featuredImage.mobile = imageMobile
    featuredImage.tablet = imageTablet
    featuredImage.desktop = imageDesktop
  }
  const { location } = data.site.siteMetadata
  location.slug = removeTrailingSlash(slug)
  const categories = data.categories.edges.map(({ node }) => ({
    ...node,
  }))
  const posts = data.posts.edges.map(({ node }) => ({
    ...node,
  }))
  const postsByCategory = sortPostsByCategory(posts, categories)
  categories.forEach((cat) => {
    const key = cat.fields.slug
      .split('/')
      .filter((i) => i)
      .pop()
    cat.articles = key in postsByCategory ? postsByCategory[key] : []
  })

  const askArchiveProps = {
    hero,
    featuredImage,
    slug,
    categories,
  }
  return (
    <Layout location={location} data={data}>
      <AskArchiveTemplate {...askArchiveProps} />
    </Layout>
  )
}

AskArchive.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AskArchive

export const AskArchiveQuery = graphql`
  query AskArchiveTemplate($id: String!) {
    site {
      ...siteMeta
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
        gitAuthorTime
        gitCreatedTime
        imageMobile {
          ...mobileHeroImage
        }
        imageTablet {
          ...tabletHeroImage
        }
        imageDesktop {
          ...desktopHeroImage
        }
      }
      frontmatter {
        ...seoFields
        ...heroFields
        ...featuredImageFields
        templateKey
      }
    }
    categories: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "archive" } } }
    ) {
      edges {
        node {
          fields {
            imageDesktop {
              childImageSharp {
                fluid(maxWidth: 430, maxHeight: 128, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          frontmatter {
            hero {
              header
            }
            featuredImage {
              alt
            }
          }
          fields {
            slug
          }
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "post" } } }
      sort: { fields: frontmatter___title }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            categories
          }
        }
      }
    }
  }
`
